<template>
    <div data-app>
        <div class="card card-custom h-100">
            <!--begin::Header-->
            <div class="card-header border-0 py-5 mb-10 mb-sm-0">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark">Form Upload Chickin</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">Silahkan upload data chickin sesuai format yang disediakan aplikasi </span>
                </h3>
                <div class="card-toolbar">
                    <a @click.prevent="getChickinThumb()" class="btn btn-icon btn-sm btn-success mr-1" v-b-tooltip.hover title="Reload Upload Chickin">
                        <i class="ki ki-reload icon-nm"></i>
                    </a>
                    <a @click="closeDialog()" class="btn btn-icon btn-sm btn-danger" v-b-tooltip.hover title="Close Modal">
                        <i class="ki ki-close icon-nm"></i>
                    </a>
                </div>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <perfect-scrollbar
            class="scroll"
            style="max-height: 90%; position: relative;"
            >
            <div class="card-body py-0 h-100">
                <div>
                    <a v-b-tooltip.hover style="min-width: 150px;" @click="downloadFile()" title="Download Template" class="btn btn-info font-weight-bolder font-size-sm mr-2">
                        <span v-if="!downloading" class="svg-icon svg-icon-md svg-icon-secondary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/Files/Import.svg" />
                            <!--end::Svg Icon-->
                        </span>
                        <span v-if="!downloading">Download Template</span>     
                        
                        <b-progress v-if="downloading" :value="progressDownload" :max="100" show-progress animated></b-progress>
                    </a>

                    <template v-if="isSave">
                        <button type="button" ref="kt_submit_form" v-b-tooltip.hover  @click="dialogSimpan = true" title="Simpan" class="btn btn-primary font-weight-bolder font-size-sm mr-2">
                            <span class="svg-icon svg-icon-md svg-icon-white">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Communication/Clipboard-check.svg" />
                                <!--end::Svg Icon-->
                            </span>
                            <span>Simpan</span>                
                        </button>
                    </template>

                    <div class="mt-2">
                        <a v-if="tempAttachments.length > 0" @click="removefiles" class="btn btn-light-primary font-weight-bold">Remove files</a>
                        <vue-dropzone
                        ref="myVueDropzone"
                        :include-styling="false"
                        :useCustomSlot="true"
                        id="dropzone"
                        @vdropzone-file-added="fileAdded"
                        @vdropzone-upload-progress="uploadProgress"
                        @vdropzone-success="uploadSuccess"
                        @vdropzone-error="uploadError"
                        :options="dropzoneOptions"
                        >
                            <a v-if="tempAttachments.length == 0" v-b-tooltip.hover title="Uplaod" class="btn btn-success font-weight-bolder font-size-sm">
                                <span class="svg-icon svg-icon-md svg-icon-secondary">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Files/Upload.svg" />
                                    <!--end::Svg Icon-->
                                </span>
                                <span>Upload</span>
                            </a>
                            <span v-else></span>
                        </vue-dropzone>
                        <span class="form-text text-muted">Ukuran file Maksimal 2MB (MegaByte) </span>

                        <div v-if="tempAttachments.length > 0" class="mt-3">  
                            <div v-for="(item, key) in tempAttachments" :key="key" class="alert alert-light" :class="{'cursor-pointer': !item.message}">
                                <p> {{ item.title }} ({{ (item.size/2000000).toFixed(2) }} MB) </p>
                                <b-progress height="7px" :value="item.progress" :max="100" show-progress animated></b-progress>
                                <span v-if="item.message" class="form-text text-danger">{{ item.message }}</span>
                            </div>
                        </div>
                    </div>
                    <template v-if="tempMessages">
                        <b-alert show variant="light">
                            <span class="text-danger font-weight-bolder">PERINGATAN!!</span>
                            <div v-for="(item, key) in tempMessages" :key="key">
                                <b-button v-b-toggle="`collapse-${key}`" variant="light-danger" size="sm" class="m-1 w-100 text-left">Error Row {{ key }}</b-button>

                                <b-collapse :id="`collapse-${key}`">
                                    <b-card>
                                        <span v-for="(child, key2) in item" :key="key2">{{ child.message }}</span>
                                    </b-card>
                                </b-collapse>
                            </div>
                        </b-alert>
                    </template>
                </div>

                <div class="d-flex flex-center py-1 px-6 bg-light rounded my-5">
                    <span class="svg-icon svg-icon-sm svg-icon-info">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Search.svg" />
                        <!--end::Svg Icon-->
                    </span>
                    <input type="text" v-model="searchThumb" class="form-control border-0 font-weight-bold pl-2 bg-light" placeholder="Ketik untuk mencari nama kandang">
                </div>
                        
                <!--begin::Table-->
                <div class="table-responsive">
                    <table class="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        <thead>
                            <tr class="text-uppercase">
                                <th @click="sort('kandang')" class="pl-0 cursor-pointer">
                                    <span :class="{ 'text-primary': currentSort == 'kandang' }">Kandang</span>
                                    <span v-if="currentSort == 'kandang' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span v-if="currentSort == 'kandang' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                </th>
                                <th @click="sort('tgl_chickin')" class="cursor-pointer d-none d-md-table-cell">
                                    <span :class="{ 'text-primary': currentSort == 'tgl_chickin' }">Chickin</span>
                                    <span v-if="currentSort == 'tgl_chickin' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span v-if="currentSort == 'tgl_chickin' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                </th>
                                <th @click="sort('jumlah')" class="cursor-pointer d-none d-md-table-cell">
                                    <span :class="{ 'text-primary': currentSort == 'jumlah' }">Jumlah</span>
                                    <span v-if="currentSort == 'jumlah' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span v-if="currentSort == 'jumlah' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                </th>
                                <th @click="sort('asal_ternak')" class="cursor-pointer d-none d-md-table-cell">
                                    <span :class="{ 'text-primary': currentSort == 'asal_ternak' }">Asal Terank</span>
                                    <span v-if="currentSort == 'asal_ternak' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span v-if="currentSort == 'asal_ternak' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="!isLoading">
                            <tr v-for="(val, key) in listThumb" :key="key">
                                <td class="pl-0">
                                    <span class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{{ val.farm.nama }} ({{ val.kode_farm }})</span><br>
                                    <span class="font-weight-bold text-muted">
                                        Flock <span class="text-primary font-weight-bolder">{{ val.kode_flock }}</span>
                                        Kandang <span class="text-primary font-weight-bolder">{{ val.kode_kandang }}</span>
                                    </span>
                                    <div class="d-inline d-md-none">
                                        <br>
                                        <span class="font-weight-bold text-muted">
                                            Chickin 
                                            <span class="text-primary font-weight-bolder">
                                                {{ val.tgl_chickin | dateIndonesia }}
                                            </span> 
                                            <b-badge variant="info" class="mx-1 mt-1">{{ val.sex.uraian }} {{ val.jumlah | nominal }} ekor</b-badge>
                                            <b-badge variant="light" class="mx-1 mt-1">{{ val.strain.uraian }}</b-badge>
                                        </span>
                                        <br>
                                        <span class="font-weight-bold text-muted">
                                            Asal Ternak 
                                            <span class="text-primary font-weight-bolder">
                                                {{ val.asalternak.nama }}
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td class="d-none d-md-table-cell">
                                    <span class="d-block text-dark-75 font-weight-bolder font-size-lg ml-1">
                                        {{ val.tgl_chickin | dateIndonesia }}
                                    </span>
                                </td>
                                <td class="d-none d-md-table-cell">
                                    <span class="d-block text-dark-75 font-weight-bolder font-size-lg ml-1">
                                        {{ val.jumlah | nominal }} ekor
                                    </span>
                                    <b-badge variant="light" class="mx-1 mt-1">{{ val.strain.uraian }}</b-badge>
                                    <b-badge variant="info" class="mx-1 mt-1">{{ val.sex.uraian }}</b-badge>
                                </td>
                                <td class="d-none d-md-table-cell">
                                    <span class="d-block text-dark-75 font-weight-bolder font-size-lg ml-1">
                                        {{ val.asalternak.nama }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td class="p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                            </tr>
                            
                            <tr>
                                <td class="p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--end::Table-->
            </div>
            </perfect-scrollbar>
            <!--end::Body-->
            <div class="card-footer">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
            </div>
        </div>
        
        <v-dialog
        v-model="dialogSimpan"
        max-width="300">
            <v-card>
                <v-card-title class="headline">Simpan data</v-card-title>

                <v-card-text>
                    Apakah anda yakin akan menyimpan data Chickin? <br> 
                    <span class="text-danger"><span class="font-weight-bolder">Peringatan !!</span> pastikan data yang akan disimpan benar dan sudah diperiksa ulang </span> 
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <b-button
                variant="link-success"
                class="m-3"
                @click="dialogSimpan = false">
                    BATAL
                </b-button>

                <b-button
                variant="link-danger"
                class="m-3"
                @click="onSubmit">
                    SIMPAN
                </b-button>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ContentLoader } from 'vue-content-loader'
import PopulasiService from "@/core/services/api/nasional/populasi.service";
import ErrorService from "@/core/services/error.service";
import JwtService from "@/core/services/jwt.service";

import vue2Dropzone from 'vue2-dropzone'

export default {
    name: "unggas-nasional-data-popuasi-form-chickin",
    props: {
        sdata: {
            required: true
        }
    },
    components: {
        ContentLoader,
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            show: true,
            modalDate: false,
            errorDate: false,
            dates: null,
            tempAttachments: [],
            dropzoneOptions: {
                url: `${process.env.VUE_APP_API_URL }/v2/nasional/populasi/chickin/file/upload`,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${JwtService.getToken()}`
                },
                maxFilesize: 1,
                maxFiles: 1,
                acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                includeStyling: false,
                previewsContainer: false,
                parallelUploads: 20
            },
            downloading: false,
            progressDownload: 0,
            file: null,
            isLoading: true,
            dialogSimpan: false,
            isSave: false,
            searchThumb: '',
            currentPage: 1,
            perPage: 10,
            currentSort:'tgl_chickin',
            currentSortDir:'asc',
            items: [],
            tempMessages: null,
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),

        filteredThumb() {
            const result = this.items.filter((row, index) => {
                if(row.kode_farm.toLowerCase().includes(this.searchThumb.toLowerCase())) return true;
                if(row.farm.nama.toLowerCase().includes(this.searchThumb.toLowerCase())) return true;
                if(row.kode_flock.toLowerCase().includes(this.searchThumb.toLowerCase())) return true;
                if(row.kode_kandang.toLowerCase().includes(this.searchThumb.toLowerCase())) return true;
            })
            return result
        },
        listThumb() {
            let sort = this.filteredThumb.slice(0).sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
            })
            const result = sort.filter((row, index) => {
                let start = (this.currentPage-1)*this.perPage;
                let end = this.currentPage*this.perPage;
                if(index >= start && index < end) return true;
            })
            return result
        },
        totalRows() {
            if (!this.filteredThumb) {
                return 1
            }
            return this.filteredThumb.length
        },
    },
    methods: {
        closeDialog(refresh = false) {
            this.onReset()
            return this.$emit('close-modal', {status: true, refresh})
        },

        sort(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
        },
        async downloadFile() {
            if(this.downloading) {
                return
            }
            this.downloading = true
            this.progressDownload = 0

            await this.axios({
                method: "post",
                url: `${process.env.VUE_APP_API_URL }/v2/nasional/populasi/chickin/file/download`,
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${JwtService.getToken()}`
                },
                onDownloadProgress: (progressEvent) => {
                    let progressDownload = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                    );
                    this.progressDownload = progressDownload;
                },
            }).then((response) => {
                let mainfilename = 'form_chickin.xlsx';
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", mainfilename);
                document.body.appendChild(link);
                link.click();
                this.downloading = false
            });
        },
        removefiles () {
            this.$refs.myVueDropzone.removeAllFiles(true);
            this.file = null
            this.tempAttachments= [];
            this.tempMessages = null
        },
        fileAdded(file) {      
            let attachment = {};
            attachment._id = file.upload.uuid;
            attachment.title = file.name;
            attachment.type = "file";
            attachment.extension = "." + file.type.split("/")[1];
            attachment.isLoading = true;
            attachment.progress = null;
            attachment.size = file.size;
            attachment.message = null;
            this.tempAttachments = [...this.tempAttachments, attachment];
        },
        uploadProgress(file, progress, bytesSent) {
            this.tempAttachments.map(attachment => {
                if (attachment._id === file.upload.uuid) {
                    attachment.progress = `${Math.floor(progress)}`;
                }
            });
        },
        uploadSuccess(file, response) {
            this.file = response.data

            this.getChickinThumb()
            ErrorService.message(response)
            this.tempMessages = null
        },
        uploadError(file, message, xhr) {
            // this.tempAttachments.map(attachment => {
            //     if (attachment._id === file.upload.uuid) {
            //     attachment.message = message;
            //     }
            // });

            this.tempMessages = message.message
        },
        removeSpinner(button) { 
            button.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        },
        getChickinThumb() {
            this.isLoading = true
            this.isSave = false
            
            PopulasiService.getChickinThumb()
                .then((res) => {
                    if (!res.data.status) {
                        ErrorService.message(res.data)
                        this.items = []
                        this.isLoading = false
                        return;
                    }
                    this.items = res.data.data
                    this.isLoading = false
                    this.isSave = true
                })
                .catch((err) => {
                    this.items = []
                    this.isLoading = false
                    return ErrorService.status(err)
                })
        },
        onSubmit() {
            // set spinner to submit button
            const submitButton = this.$refs["kt_submit_form"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            // dummy delay
            // setTimeout(() => {
                PopulasiService.addChickinThumb()
                    .then((res) => {
                        this.removeSpinner(submitButton)
                        ErrorService.message(res.data)
                        
                        if (res.data.status) {
                            return this.closeDialog(true)
                        }
                        return;
                    })
                    .catch((err) => {
                        this.removeSpinner(submitButton)
                        ErrorService.status(err)
                    })

            // }, 1000);
        },
        onReset () {
            this.items = []
            this.isLoading = true

            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    mounted() {
        if(!this.currentUser.akses) {
            return this.$router.push({ name: 'auth-select-company', query: { rget: this.$route.path }}).catch(()=>{})
        }

        this.getChickinThumb()
    },

}
</script>